import React, { useEffect } from 'react'
import reviews from '../assest/reviews.jpg'
import customer1 from '../assest/customer1.png'
import customer2 from '../assest/customer2.png'
import customer3 from '../assest/customer3.png'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
// import required modules
import { Autoplay } from 'swiper/modules';
import { useDispatch, useSelector } from 'react-redux'
import { fetchCustomers } from '../rtk/slices/customer-slice'

function Reviews () {
    // أضف المنطق لتحديد عدد العناصر المرئية بناءً على عرض الشاشة
    const slidesPerView = window.innerWidth >= 768 ? 5 : 3;

    const dispatch = useDispatch();
    const customers = useSelector((state) => state.customers.customers);

    useEffect(() => {
        // fetch show
        dispatch(fetchCustomers());
    }, [])
    return (
        <section className='reviews'>
            <div className="container">
                <div className="box-content">
                    <div className="image">
                        <img src={reviews} alt="" />

                    </div>
                    <div className="content">
                        <p>نطمح الي أن نصبح واحدة من ابرز وجهات التسويق عبر الانترنت علي مستوي العالم. نريد ان نكون ان نكون مرشدك الاول للتجارة الإلكترونية , حيث نقدم تشكيلة واسعة من المنتجات والخدمات التي تلبي تفضيلات واحتياجات مختلفة , مع التميز في الجودة والابتكار.</p>
                        <button className='btn'><a href="mailto:sazksa49@gmail.com">تواصل معنا</a></button>
                    </div>
                </div>

                <div className="customer">
                    <h4>كثير من عملائنا المخلصين يثقون بنا</h4>
                    <div className="cards">

                        <Swiper
                            spaceBetween={30}
                            initialSlide={3}
                            slidesPerView={slidesPerView}
                            centeredSlides={true}
                            autoplay={{
                                delay: 300000,
                                disableOnInteraction: false,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={false}
                            modules={[Autoplay]}
                            className="mySwiper"
                        >
                            {customers.map((customer) => {
                                return (
                                    <SwiperSlide>
                                        <div className="card-swiper">
                                            <img src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${customer.image}`} alt="" />
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Reviews
