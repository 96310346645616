import { configureStore } from "@reduxjs/toolkit"
import visitorSlice from "./slices/visitor-slice"
import colorSlice from "./slices/color-slice"
import logoSlice from "./slices/logo-slice"
import serviceSlice from "./slices/service-slice"
import customerSlice from "./slices/customer-slice"

export const store = configureStore({
    reducer: {
        visitor: visitorSlice,
        color: colorSlice,
        logo: logoSlice,
        services: serviceSlice,
        customers: customerSlice,
    }
})