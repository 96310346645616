import React from 'react'
import landing from "../assest/landing.jpg"
import bg from "../assest/bg.jpg"

function Home () {
   
    return (
        <div className='home' id='home'>
            <div className="bg">
                <img src={bg} alt="" />
            </div>
            <div className="container">
                <div className="content">
                    <div className="box-text">
                        <h2>نساعدك للوصول لعملائك بأفضل الوسائل وبأقل تكلفة</h2>
                        <p>تقدم شركتنا حلول رقمية كاملة تتسم بالمرونة والفاعلية بما في ذلك الإنتاج الرقمي, وإدارة المحتوي الإبداعي لوسائل التواصل الإجتماعي.</p>
                        <button className='btn'><a href="mailto:sazksa49@gmail.com">تواصل معنا</a></button>
                    </div>
                    <div className="box-image">
                        <img src={landing} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home
