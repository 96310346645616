import About from "./components/About";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Reviews from "./components/Reviews";
import Services from "./components/services";


function App () {
  return (
    <div className="App">
      <Navbar />
      <Home />
      <Services />
      <About />
      <Reviews />
      <Footer />
    </div>
  );
}

export default App;
