import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const fetchCustomers = createAsyncThunk('customersSlice/fetchCustomers', async () => {
    const res = await fetch(`${process.env.REACT_APP_PROJECTS_API}components/s_d.php?customers`)
    const data = await res.json()
    return data;
})

const customersSlice = createSlice({
    initialState: {
        customers: [],
        loading: false,
        error: null,
    },
    name: 'customersSlice',
    extraReducers: (builder) => {
        builder
            .addCase(fetchCustomers.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchCustomers.fulfilled, (state, action) => {
                state.loading = false;
                state.customers = action.payload;
            })
            .addCase(fetchCustomers.rejected, (state, action) => {
                state.loading = false;
                state.error = <p className='error-api'>The server is temporarily down Please try later!</p>;
            });
    },
})

export const { } = customersSlice.actions;

export default customersSlice.reducer;