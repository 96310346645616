import React from 'react'
import poster from "../assest/about.jpg"


function About () {
    return (
        <section className='about'>
            <div className="container">
                <h3>مهمتنا</h3>
                <div className="box-about">
                    <div className="icon"><i class="fa fa-angle-double-left"></i></div>
                    <div className="text">
                        <p>هي تقديم تجربة خدمة استثنائية لعملائنا , من خلال توفير خدمات تسويقية عالية الجودة وخدمات عملاء السوق وفاعلية وتغلب علي المنافسين للوصول لإفضل نتائج ممكنة</p>
                    </div>
                </div>
                <div className="box-icon">
                    <div className="box">
                        <div className="img">
                            <i class="fa fa-bullhorn"></i>
                        </div>
                        <p>تقدم للعملاء أفضل الحلول المبتكرة لتسويق الإلكتروني</p>
                    </div>
                    <div className="box">
                        <div className="img">
                            <i class="fa fa-check-circle"></i>
                        </div>
                        <p>نكون الاختيار الأول للمستثمرين الباحثين عن الجودة والأمانة معا في هذا المجال</p>
                    </div>
                    <div className="box">
                        <div className="img">
                            <i class="fa fa-cogs"></i>
                        </div>
                        <p>خدمتنا دائما فوق مستوي توقعات عملائنا مع توفير الوقت وأهمية الاتقان في العمل</p>
                    </div>
                </div>
            </div>
            <div className="poster">
                <div className="container">
                    <div className="poster-text">
                        <div className="box-poster-text">
                            <h4>امتلك موقعا إلكترونيا للعمل الخاص بك مع خطة تسويقية بأسعار خيالية</h4>
                            <h4>اذا كان لديك طلب بمواصفات خاصة, يتم حساب التكلفة بناء علي التفاصيل المطلوبة عليك التواصل معنا فقط</h4>
                        </div>
                        <button id='contact' className='btn'><a href="mailto:sazksa49@gmail.com">اطلب موقعك الأن</a></button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About
