import React, { useEffect } from 'react'
import scroll from "../assest/scroll.jpg"
import service1 from "../assest/service1.jpg"
import service2 from "../assest/service2.jpg"
import service3 from "../assest/service3.jpg"
import service4 from "../assest/service4.jpg"

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
// import required modules
import { Autoplay } from 'swiper/modules';
import { useDispatch, useSelector } from 'react-redux'
import { fetchServices } from '../rtk/slices/service-slice'


function Services () {
    // أضف المنطق لتحديد عدد العناصر المرئية بناءً على عرض الشاشة
    const slidesPerView = window.innerWidth >= 830 ? 3 : 1;

    const dispatch = useDispatch();
    const services = useSelector((state) => state.services.services);

    useEffect(() => {
        // fetch show
        dispatch(fetchServices());
    }, [])
    return (
        <section className='services' id='services'>
            <div className="container">
                <div style={{ textAlign: "center" }} className="scroll">
                    <img src={scroll} alt="" />
                </div>
                <div className="cards">
                    <Swiper
                        spaceBetween={30}
                        initialSlide={2}
                        slidesPerView={slidesPerView}
                        centeredSlides={true}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={false}
                        modules={[Autoplay]}
                        className="mySwiper"
                    >
                        {services.map((service) => {
                            return (
                                <SwiperSlide key={service.id}>
                                    <div className="card">
                                        <img src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${service.image}`} alt="" />
                                        <h4>{service.name}</h4>
                                    </div>
                                </SwiperSlide>
                            )
                        })}

                    </Swiper>


                </div>
                <button className="btn">
                    المزيد
                </button>
            </div>
        </section>
    )
}

export default Services
