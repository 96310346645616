import React, { useEffect } from 'react'
import logos from '../assest/logo.png'
import { useDispatch, useSelector } from 'react-redux';
import { fetchLogo } from '../rtk/slices/logo-slice';

function Footer () {
    const dispatch = useDispatch();
    const logo = useSelector((state) => state.logo.logo);

    useEffect(() => {
        // fetch show
        dispatch(fetchLogo());
    }, [])
    return (
        <footer>
            <div className="container">
                <div className="top-content">
                    <div className="box">
                        <h3>خدمتنا</h3>
                        <ul>
                            <li>
                                تطوير تطبيقات المحمول
                            </li>
                            <li>
                                إدارة الجملات الإعلانية لشبكات التواصل</li>
                            <li>
                                تصميم وتطوير المواقع الإلكترونية</li>
                        </ul>
                    </div>
                    <div className="box center">
                        <ul>
                            <li>
                                ورشة الحملات الإعلانية لشبكات التواصل
                            </li>
                            <li>
                                تقييم وتحليل المواقع الإلكترونية</li>
                            <li>
                                إنشاء المتاجر الإلكترونية</li>
                        </ul>
                    </div>
                    <div className="box bottom">
                        <h3>تواصل معنا</h3>
                        <ul>
                            <li>
                                المملكة العربيه السعوديه مكه
                            </li>
                            <li>
                                966538028094+
                            </li>
                            <li>
                                sazksa49@gmail.com
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="center-content">
                    <div className="img">
                        <img src={logo.length > 0 ? `${process.env.REACT_APP_PROJECTS_API}assest/imgs/${logo[0].logo}` : logos} alt="" />
                    </div>
                    <div className="contact">
                        <h3>علي استعداد للبدأ؟ <a href="mailto:sazksa49@gmail.com">اتصل بنا الأن</a></h3>
                    </div>
                </div>

                <div className="bottom-content">
                    <div className="icons">
                        <a href="#"><i class="fa fa-linkedin"></i></a>
                        <a target='_blank' href="https://www.instagram.com/sazksa2"><i class="fa fa-instagram"></i></a>
                        <a href="#"><i class="fa fa-twitter"></i></a>
                        <a href="#"><i class="fa fa-tiktok"></i></a>
                        <a href="#"><i class="fa fa-facebook"></i></a>
                    </div>
                    <div className="copyright">
                        <p>تطوير وتسويق SAZ للتسوق الإلكتروني</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
